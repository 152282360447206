.ant-modal-header{
    margin-bottom: 10px !important;
}
.ant-modal-header .ant-modal-title{
    border-bottom: 1px solid #f5f5f5;
    padding-bottom: 10px;
    font-family: 'Inter';
}
.Form-Item-Input .ant-form-item-row{
    position: relative;
}
.Form-Item-Input .ant-form-item-label{
    position: absolute;
    z-index: 1;
    background-color: #fff;
    top: -13px;
    left: 10px;
    line-height: 18px;
}
.Form-Item-Input .ant-form-item-label label{
    height: auto;
    font-size: 11px;
    padding: 0 0 0 5px;
    font-family: 'Inter';
}
.Form-Item-Input .ant-input{
    padding-top: 6px;
    padding-bottom: 6px;
}
.Form-Separador{
    width: 100%;
    height: 1px;
    background-color: #f5f5f5;
    margin-bottom: 20px;
}
.Form-Item-Select{
    width: 100%;
}
.Form-Item-Select .ant-select-selector{
    background-color: #f5f5f5 !important;
}
.Form-Item-Input .ant-input.Input-Modal, .Form-Item-Input .ant-picker.Date-Modal{
    font-family: 'Inter';
    background-color: #f5f5f5;
    color: #7b7b7b;
}
.Form-Item-Input .ant-picker.Date-Modal{
    width: 100%;
}
.Form-Item-Input .ant-picker.Date-Modal input{
    color: #7b7b7b;
}
.Button-Green-Modal{
    border-color: rgb(24, 236, 95) !important;
    color: rgb(24, 236, 95) !important;
}