.Col-Banner-Login{
    background-color: #24235F;
    display: flex;
    padding: 70px 30px 70px 30px;
    flex-direction: column;
    justify-content: center;
    position: relative;
}
.Icon-Banner-Login{
    width: 200px;
    height: 50px;
    border-radius: 10px;
    /* background-color: lightsalmon; */
    position: absolute;
    top: 20px;
    left: 30px;
}

.Icon-Title-Login img{
    width: 70px;
}

.Icon-Form-Login img{
    width: 220px;
}

.Title-Banner-Login{
    font-family: 'Inter';
    font-size: 60px !important;
    color: aqua !important;
    line-height: 85px !important;
    margin-bottom: 3px !important;
}
.Text-Banner-Login{
    font-family: 'Inter';
    color: white !important;
}
.Col-Form-Login{
    padding: 70px 30px 70px 30px;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.Icon-Form-Login{
    width: 200px;
    height: 50px;
    border-radius: 10px;
    /* background-color: lightsalmon; */
    position: absolute;
    top: 20px;
    left: 30px;
}
.Container-Title-Login{
    width: 400px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
}
.Title-Form-Login{
    font-family: 'Inter';
    font-weight: bold !important;
    color: darkblue !important;
    margin-bottom: 0 !important;
}
.Icon-Title-Login{
    width: 80px;
    height: 80px;
    /* background-color: lightsalmon; */
    border-radius: 50%;
}
.Form-Container-Item-Login{
    width: 400px;
}
.Item-Form-Login label{
    font-family: 'Inter';
    color: darkblue !important;
    font-weight: bold;
    margin-left: 10px !important;
}
.Item-Form-Login .Input-Form-Login{
    padding-top: 10px;
    padding-bottom: 10px;
}
.Item-Form-Login .Input-Form-Login .ant-input-prefix{
    margin-inline-end: 7px;
}
.Item-Form-Login .Input-Form-Login input::placeholder{
    font-family: 'Inter';
    font-weight: bold;
}
.Button-Form-Login{
    width: 100%;
    height: auto !important;
    background-color: dodgerblue;
    color: white;
    border: 0;
    border-radius: 20px;
    text-transform: uppercase;
    font-weight: 600;
    padding-top: 5px;
    padding-bottom: 5px;
}
.Button-Form-Login:hover{
    color: white !important;
}